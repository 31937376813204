<template>
  <TooltipRoot :open="open || insideOpen">
    <span class="tooltip_wrapper" @mouseover="insideOpen = true" @mouseleave="insideOpen = false">
      <TooltipTrigger as-child>
        <slot></slot>
      </TooltipTrigger>
    </span>
    <TooltipPortal>
      <TooltipContent v-bind="newContentProps" class="TooltipContent" hide-when-detached>
        <slot name="content">{{ content }}</slot>
        <TooltipArrow class="TooltipArrow" />
      </TooltipContent>
    </TooltipPortal>
  </TooltipRoot>
</template>

<script setup lang="ts">
import {
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  type TooltipContentProps,
} from "radix-vue";

const props = defineProps<{
  contentProps?: TooltipContentProps;
  content?: string;
  open?: boolean;
}>();

const newContentProps: TooltipContentProps = { sideOffset: 4, avoidCollisions: true, ...props.contentProps };

const insideOpen = ref(false);
</script>

<style lang="scss">
.tooltip_wrapper {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.TooltipContent {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: var(--gray-1);
  height: 32px;
  padding-inline: 8px;
  gap: 16px;
  font-weight: 500;
  color: var(--white);
}

.TooltipArrow {
  fill: var(--gray-1);
}
</style>
